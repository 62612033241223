* {
    margin: 0;
    border: 0;
    padding: 0;
    box-sizing: border-box;
}

.fb{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    z-index: 99999999;

}

.fb-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 400px; /* Adjust the width as needed */
    margin: 0 auto; /* Center the content horizontally */
    padding: 20px;
    border: 2px solid #007bff; /* Add a border for visual appeal */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
    text-align: center;
    width: 90%;
  }
  
  .fb-wrapper p {
    font-size: 1em;
    margin-bottom: 10px;
    color: #F0F0F0;;
  }
  
  .fb-wrapper .sign-in-img img {
    max-width: 100%;
    height: 400px; /* Maintain image aspect ratio */
    margin-top: 10px;
    border: 2px solid #007bff; /* Add a border around the image */
    border-radius: 10px; /* Rounded corners for the image */
  }
  



.sign-in-status{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    z-index: 9999;
    overflow-y: scroll;
}

.sign-in-status .action{
    display: flex;
    justify-content: space-around;
    /* height: 15vh; */
    height: 35vh;
    flex-direction: column;
    margin-left: 2%;
    font-weight: bold;
}

.sign-in-status .sign-in-page{

    width: 95%;
    margin-top: 20%;
    height: 70vh;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.sign-in-status .sign-in-page button{
cursor: pointer;


}
.sign-in-status .sign-in-page button {
    height: 80px;
    border-radius: 5px;
    font-size: 1.8em;
    display: flex;
    width: 90%;
    margin: auto;
    margin-top: 15%;
    align-items: center;
    justify-content: center;
    background-color: #ffffff; /* White background */
    color: #007BFF; /* Change to your desired text color */
    border: 1px solid #007BFF; /* Add a border for better visibility */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease, color 0.3s ease;
}

.sign-in-status .sign-in-page button:hover {
    background-color: #f2f2f2; /* A slightly different white on hover */
    transform: scale(1.05); /* Add a slight scaling effect on hover */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow on hover */
    color: #0056b3; /* Change the text color on hover */
}

.sign-in-page form{
    display: flex;
    flex-direction: column;
}

.sign-in-page form .sign-in-div{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100px;
    
}

.sign-in-div label{
    margin-top: 4%;
    font-size: large;
}

.sign-in-page form .sign-in-div input[type="email"]{

height: 80px;
width: 90%;
border-radius: 5px;
font-size: large;

}

.vote-numbers{

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #161616;
    backdrop-filter: blur(25px);
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    
}

.uViews{

    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
}

  .success {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    z-index: 9999;
  }
  
  
  .link-to-global {
    text-align: center;
    padding: 50px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
  
  .link-to-global h3 {
    font-size: 1.5rem;
    margin: 10px 0;
    color: #00b41e;
  }
  
  #link-to-global {
    display: inline-block;
    padding: 10px 30px;
    background: linear-gradient(to right, #00b41e, #009514);
    color: white;
    text-decoration: none;
    border-radius: 25px;
    font-weight: bold;
    transition: background 0.3s, color 0.3s;
  }
  
  #link-to-global:hover {
    background: linear-gradient(to right, #009514, #00b41e);
    color: white;
  }
  
  

.overflow-scroll {
    /* Set the color of the scroll track */
    scrollbar-color: #00b41e transparent;
  }
  
  .overflow-scroll::-webkit-scrollbar-thumb {
    /* Set the color of the scroll thumb for webkit-based browsers */
    background-color: #00b41e;
  }
  
  .install-btn {
    position: fixed;
    bottom: 10%;
    left: 5%;
    width: 90%;
    max-width: 400px; /* Added max-width for larger screens */
    height: 60px; /* Increased height for better visibility */
    background: linear-gradient(135deg, #00b41e, #00cc66); /* Updated background with a gradient */
    color: #ffffff; /* Changed text color to white for better contrast */
    border: none; /* Removed border for a cleaner look */
    border-radius: 30px; /* Increased border-radius for a more rounded appearance */
    font-size: larger;
    font-weight: bold; /* Added font-weight for emphasis */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Added a subtle shadow */
    cursor: pointer; /* Changed cursor to indicate interactivity */
    z-index: 9999;
    transition: background 0.3s ease, transform 0.2s ease; /* Added transitions for interactivity */
}

.install-btn:hover {
    background: linear-gradient(135deg, #00cc66, #00b41e); /* Updated background gradient on hover */
    transform: scale(1.05); /* Slight scale-up on hover for an interactive effect */
}


a {
    text-decoration: none;
    color: #FFFDD0;

}



.HomePage{
    margin-top: 2%;
}
.intro-meme {
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro-text {
    width: 85%;
    margin: auto;
    color: #FFFDD0;
    margin-top: 1%; /* Slightly reduced margin-top */
    text-align: center;
    padding: 0.8em; /* Reduced padding for a more compact look */
    background-color: rgba(27, 24, 31, 0.9);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px; /* Reduced border radius for a more compact appearance */
    font-size: 0.9em; /* Slightly reduced font size */
    line-height: 1.4; /* Slightly reduced line spacing */
    margin-top: 2%;
  }
  
  /* Hover effect */
  .intro-text:hover {
    transform: translateY(-2px); /* Slight vertical lift on hover */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Lighter box shadow on hover */
  }
  

  .intro-meme img {
    width: 85%;
    max-height: 250px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* Hover effect */
  .intro-meme img:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  

.home-page-btn {
    height: 50px;
    width: 48%;
    margin-left: 1%;
    margin-top: 4%;
    background: linear-gradient(90deg, #00b41e 0%, #0091ea 100%); /* Gradient background */
    color: #FFFDD0;
    border: none;
    border-radius: 25px; /* Larger radius for a smoother look */
    font-size: .9em; /* Slightly increased font size */
    font-weight: bold;
    letter-spacing: 1px; /* Adding some spacing between letters */
    transition: background 0.3s ease, transform 0.3s ease; /* Smooth transitions */
    cursor: pointer;
  }
  
  /* Hover effect */
  .home-page-btn:hover {
    background: linear-gradient(90deg, #0091ea 0%, #00b41e 100%); /* Reversed gradient on hover */
    transform: scale(1.05); /* Slight scale up on hover */
  }
  

#copy-text{
    bottom: 4%;
    left: 30%;
    background:#331e0d;
}

#copy-btn{
    width: 50%;
    
}

.name-logo{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: #FFFDD0;
    height: 15vh;
}



.name-logo .logo img{

    width: 80px;
    height: 80px;
    object-fit: contain;
   
}

.container{
    height: 75vh;
    
}

::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

.header-details {

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}


.header-details h2 {
    color: #FFFDD0;
    font-size: .8em;
}

.header-details .logo {
    display: flex;
    align-items: center;
    margin-left: 8%;
}

.header-details .search {
    margin-right: 10%;
}

.header-details .logo img {
    height: 35px;
    width: 35px;
    object-fit: contain;
}

.header-details .search-input {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #161616;
    backdrop-filter: blur(25px);
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    
}

.header-details .search-input input[type='search'] {

    height: 45px;
    width: 90%;
    background: #FFF;
    margin-top: 15%;
    margin-left: 4.5%;
    border-radius: 5px;
    font-size: 1.1em;
}

.header-details .search-input .close-modal {
    color: #FFF;
}

.search-result {
    display: flex;
    flex-direction: column;
    height: 290px;
    width: 85%;
    margin: auto;
    margin-top: 5%;
    
    
}

.search-result .profile-img img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%;

}

.search-result .profile-img{
    width: 50%;
}

.search-result .username{
    width: 50%;
}

.search-result  .username h4{
    font-size: .8em;
}

.search-result .search-link {
    display: flex;
    align-items: center;
}

.search-res-wrapper {

    height: 50px;
    width: 90%;
    margin: auto;
    margin-top: 3px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(25px);
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
}

.inner-search-res-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    margin: auto;

}


/* .header-details .left-light{
    position: absolute;
    left: 0;
    top: 22%;
    height: 50px;
    width: 50px;
    background: linear-gradient(#e91e63,#ffc107);
    border-radius: 50%;
}

.header-details .right-light{
    position: absolute;
    right: 0;
    top: 22%;
    z-index: -10;
    height: 50px;
    width: 50px;
    background: linear-gradient(#2196f3,#31ff38);
    border-radius: 50%;
}  */

.header-navigation {
   
    display: flex;
    justify-content: space-around;
    margin-top: 4%;
    margin-bottom: 4%;

}


.header-navigation .post-filter-links {
    width: 100px;
    text-align: center;
    padding: .3em;
    border-radius: .3em;
}

.active {
    border-bottom: 2px solid #00b41e;
}

.notActive {
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

.main-active {
    color: #00b41e;
}

body {
    background-color: #161616;


}


.sign-in-page {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 30vh;
    justify-content: space-evenly;
    margin: auto;
    color: #FFFDD0;
    font-size: .7em;
    margin-top: 20%;
    
}

.sign-in-page .google-icon {
    margin-right: 5px;
}

.sign-in-page button {
    height: 50px;
    border-radius: 5px;
    font-size: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sign-in-page .action {
    font-weight: medium;
    font-size: 1.9em;
}

.main-navigation-menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    width: 100%;
    background-color: rgba(27, 24, 31, 255);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    bottom: 0;
    position: fixed;
    z-index: 1;

}

.main-navigation-menu .links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.main-navigation-menu a {
    font-size: .8em;
    width: 20%;

}

.post-parent {
    margin-bottom: 14%;


}



.caption {
    color: #FFFDD0;
    width: 90%;
    margin: auto;
}

.profile {
    display: flex;
    width: 95%;
    margin: auto;
    align-items: center;
    margin-bottom: 2%;
    color: #FFFDD0;
    justify-content: space-around;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.profile-photo img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.profile button {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(25px);
    padding: .3em .8em .3em .8em;
    color: #FFFDD0;
}

/* Tv Design */

/** HappyTimes1.1 TV Design **/

.post-loading{

    position: absolute;
    top: 20%;
    left: 36%;

}

.views{
    display: flex;
    align-items: center;

}

.views p{
    color: #161616;
}

.Tv-1 {
    display: flex;
    flex-direction: column;
    height: 70vh;
    width: 99%;
    margin: auto;
    border: 10px solid #c7c6b2;
    border-radius: 20px;

}

.Tv-1 .monitor {
    height: 85%;
    width: 100%;
    background: #FFF;
    border-radius: 10px;

}






.Tv-1 .rxns-container {
    display: flex;
    height: 15%;
    background: #c7c6b2;
    border: 2px solid #331e0d;
    width: 100%;
}

.Tv-1 .monitor img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.Tv-1 .monitor video {
    height: 57.3vh;
    width: 100%;
    object-fit: contain;
    border-radius: 10px;

}

.Tv-1 .rxns-container .post-reactions {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 75%;

}
.rxns{

    cursor: pointer;
}
.rxns-parent {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    width: 80%;
}

.Tv-1 .rxns-container .post-reactions .rxns {
    display: flex;
    align-items: center;

}

.Tv-1 .rxns-container .post-reactions .count {
    margin-left: 5px;
    color: #000;
}

.Tv-1 .rxns-container .tv-speaker {
    position: relative;
    overflow: hidden;
    width: 25%;
}

.speaker-lines {
    width: 100%;
    height: 1px;
    background: #331e0d;
    margin-top: 1px;
}

.speaker-circle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 12%;
    left: 22%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #331e0d;
}

.speaker-dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: 1px solid #FFF;
}

.copy-div{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 50%;
   
   
}

.copy-div input{
    
    background-color: #FFFDD0;
    word-break: break-word;
    margin-bottom: 2%;
}

.copy-div button{

    background: #00b41e;
    color: #331e0d;
    width: 40%;
    height: 30px;
    cursor: pointer;
}

.commentsModal {
    position: fixed;
    background-image: url(../images/verticalScroll-min-removebg-minco.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 70%;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: auto;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    color: #161616;
    /* overflow: scroll; */


}

#comment-loading{

    position: absolute;
    left: 40%;
   
}

.parent-comment {
    overflow-y: scroll;
    position: relative;
    height: 60%;
    margin-top: 1%;
}

.modal-btn {
    margin-left: 85%;
    height: 40px;
    width: 40px;
    font-size: 1em;
    font-weight: bolder;
    background-color: #000;
    color: #FFF;
    margin-bottom: 45px;
    cursor: pointer;
}

.commentsModal .comment-box {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: auto;
    justify-content: space-around;
  

}

.individual-comment {

    word-break: break-word;
    
}



.commentsModal .comment-box img {

    height: 30px;
    width: 30px;
    border-radius: 50%;
}

.comments-profile {
    display: flex;
    width: 100%;
    align-items: center;


}

.comments-profile .username h5 {

    margin-left: .4em;
}



.comment-vote {
    display: flex;
    justify-content: space-between;
    
}

.comment-votes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}



  


.comment-replies {
    margin-left: 20px;
}

#comment-reply img {

    height: 20px;
    width: 20px;
    border-radius: 50%;
}

#username-comment-reply h5 {

    font-size: .7em;
}

.date-reply {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
}

.date-reply .date {

    margin-right: 4px;
}

.click-to-view-reply,
.click-to-hide-replies {
    font-weight: bold;
    margin-bottom: 3px;
}

.comment-form {
    position: fixed;
    bottom: 10%;
    left: 17%;
    display: flex;
    width: 65%;
    margin: auto;
}

.comment-form form {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: space-evenly;

}

.textarea {
    width: 90%;


}

.btn-submit {
    width: 7%;


}

.comment-form form button {
    margin-left: 2px;
    text-align: center;
    background: #fbe478;

}

.comment-form img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
}

.comment-form textarea {
    background: #FFFDD0;
    text-align: center;
    margin-left: 2px;
    width: 100%;
    border: 1px solid #000;
    height: 40px;
    border-radius: 5px;

}

.profile-pages {
    color: #DCDCDC;

}

.profile-pages .user-personal-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 30vh;
    margin-top: 8%;

    /* background-image: url(../images/blurry-gradient-haikei.svg);
    background-repeat: no-repeat;
    background-size: cover; */

}

.profile-pages .user-personal-details .user-profile-img img {

    height: 80px;
    width: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 5px;

}

.profile-pages .user-personal-details .edit-account button {

    background: #00b41e;
    padding: 10px;
    color: #FFFDD0;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;

}

.profile-pages .user-personal-details .users-posts-reactions {
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: auto;
}

.account-links {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.accounts-sidebar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    z-index: 9999;
}

.follow-modal-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    background-color: #161616;

}

.follow-modal {
    position: fixed;
    height: 40vh;
    bottom: 0;
    left: 0;
    right: 0;
    background: #161616;
    backdrop-filter: blur(25px);
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
    z-index: 9999;
}

.follow-modal .close-modal {
    color: #FFF;
}

.follow-modal .follow-menu {
    display: flex;
    height: 45%;
    margin-top: 10%;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.follow-modal .follow-menu button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFDD0;
    font-size: 1em;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(25px);
    width: 35%;
    height: 30px;
    border-radius: 5px;
}

.follow-icon {
    margin-right: 4px;
}

.accounts-sidebar {
    top: 0;
    bottom: 0;
    right: 0;
    width: 60%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(25px);
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
    position: absolute;
}

.sign-out-details {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sign-out {
    background: #ed1c24;
    color: #FFF;
    font-weight: bold;
    width: 50%;
    height: 40px;
    margin-top: 20%;
    border-radius: 5px;
}

.accounts-sidebar-close,
.close-modal {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 5%;
    top: 1%;
    font-size: 1em;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: 1px solid #ed1c24;
}

.edit-account-details-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    z-index: 9999;
}

.edit-account-details {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(25px);
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
}

.edit-account-modal {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: 1px solid #ed1c24;
    right: 5%;
    top: 2%;
    font-size: 1em;
    color: #FFFDD0;

}

.edit-acc {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 16%;

}

#edit-ac-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#edit-ac-profile h4 {
    position: relative;
    color: #FFFDD0;
    font-weight: lighter;
}

.update-username {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4%;



}

#profile-upload {

    position: absolute;
    top: 15%;
    color: #161616;
    font-size: .9em;
    font-weight: bolder;
}

#profile-loading {
    color: #FFFDD0;
}

.update-username input[type='text'] {
    background: #FFFDD0;
    font-weight: lighter;
    margin-top: 2%;
    height: 45px;
    width: 80%;
    border-radius: 5px;
    font-size: 1em;
    text-align: center;
}

.update-username button {
    background: #00b41e;
    margin-top: 6%;
    font-size: 1em;
    border-radius: 5px;
    color: #FFFDD0;
    width: 60%;
    height: 45px;
}

.acc-edit-icon {
    position: absolute;
    top: 25%;
}

.profile-label {
    display: none;
}

.profile-pages .user-personal-details .users-posts-reactions .reactions {

    display: flex;
    flex-direction: column;
    height: 50px;
    font-size: .9em;
    align-items: center;
    justify-content: space-around;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(25px);
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
    padding: .8em;
    border-radius: .3em;
    width: 80px;

}

.account-posts {
    display: flex;
    height: 50vh;
    overflow: hidden;
}

.account-posts .larger-video {

    height: 100%;
    width: 50%;
    margin-right: 2px;
    margin-top: 2px;
}

.account-posts .larger-video video {

    height: 50vh;
    width: 100%;
    object-fit: cover;
}

.account-posts .larger-video img {

    height: 50vh;
    width: 100%;
    object-fit: cover;
}

.account-posts .smaller-videos {

    height: 100%;
    width: 50%;
}

.account-posts .smaller-videos .first-video {
    height: 50%;
    width: 100%;
    margin-bottom: 2px;
    margin-top: 2px;
    
}

.account-posts .smaller-videos .first-video video {
    height: 25vh;
    width: 100%;
    object-fit: cover;
}

.account-posts .smaller-videos .second-video {
    height: 50%;
    width: 100%;
}

.account-posts .smaller-videos .second-video video {
    height: 25vh;
    width: 100%;
    object-fit: cover;
}



.pic-username {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-profile-nav {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    height: 50px;
    background: #00b41e;
    margin-bottom: 4%;
}

#sign-in-details {
    position: absolute;
    color: #FFFDD0;
    top: 35%;
    left: 15%;
    width: 70%;

}

.sign-in-details {
    display: flex;
    height: 200px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.accounts-kebab {
    position: absolute;
    top: 5%;
    right: 4%;
}

/*Wall of fame syling */
.wall-of-fame {
    height: 100vh;
    overflow-y: scroll;
    margin-bottom: 30%;

}

.wallOfFameHeading {
    color: #00b41e;
    display: flex;
    justify-content: center;
    margin: 4px;
}


.wall-of-fame-screen {
    border: 10px solid #cbcab6;
    height: 40vh;
    width: 98%;
    margin: auto;
    border-radius: 20px;
}

.wall-of-fame-screen video {
    height: 37.4vh;
    border-radius: .5em;
    width: 100%;
    object-fit: cover;
}

.wall-of-fame-screen img {
    height: 37.1vh;
    width: 100%;
    object-fit: cover;
}

#top-comment-heading {
    margin-top: 10%;
    color: #00b41e;
    margin-left: 2%;
}


.top-comment p {
    text-align: center;
    margin-top: 6px;

}

.top-posts-comments-nav {
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.top-posts-comments-nav .links {
    /* padding:0.5em ;
    background: rgba(255,255,255,0.1);
    backdrop-filter: blur(25px);
    box-shadow: 0 25px 45px rgba(0,0,0,0.1);
    width: 150px;
    border-radius: .3em;
    text-align: center; */

    width: 98%;
    margin: auto;
}

.top-posts-comments-nav .links .Links {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.top-ten-post-title {

    color: #00b41e;
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-top: 1%;
   
}
.height{
    height: 250px;
    width: 100%;
    
}
/* .top-ten-post-previews,.top-comments, .top-global{
    display: flex;
    
    height: 105px;
    width: 98%;
    margin: auto;
   border: 2px solid red;
    
}



.top-ten-post-previews video{
    height: 100px;
    
    object-fit: cover;
    
   
    }
    .top-ten-post-previews img{
        height: 100px;
        width: 25%;
       
        } */
/* .top-ten-post-previews {
            display: flex;
            justify-content: space-between;
            width: 98%;
            margin: auto;
            margin-top: 3%;
            height: 105px;
            border: 1px solid rgba(255,255,255,0.2);
            border-radius: 5px;
          }
          
          .top-ten-post-previews > * {
            flex: 1;
            height: 100px; 
          }
          
          .top-ten-post-previews video {
            width: 100%;
            object-fit: cover;
            height: 100px;
            
            
          } */

.top-ten-post-previews {
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin: auto;
    margin-top: 3%;
    height: 105px;
    border-radius: 5px;
    overflow: hidden;

}

.top-ten-post-previews>* {
    flex: 1;
    height: 100px;
    /* Adjust the height as needed */
    margin-right: 5px;
    /* Add margin to create space between videos */
}

.top-ten-post-previews>*:last-child {
    margin-right: 0;
    /* Remove margin from the last video */
}

.top-ten-post-previews video {
    width: 100%;
    object-fit: cover;
    height: 100px;
}


.top-ten-post-previews img {
    width: 100%;
    object-fit: cover;
    height: 100px;

}

#top-ten-component {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
}

/* .post-upload{
    display: flex;
    position: fixed;
    background: #00b41e;
    right: 5%;
    bottom: 14%;
    align-items: center;
    justify-content: center;
    width: 50px;
    height:50px;
    border-radius: 50%;
} */
.top-comment{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 1%;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(27, 24, 31, 255);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    padding: .4em;
}

.username-profile-picture{

    display: flex;
    flex-direction: column;
   align-items: center;
   width: 50%;
}

.top-comment-text{
    display: flex;
    justify-content: center;
    width: 50%;
    word-break: break-word;
}

#post-link {
    background: #00b41e;
    padding: .4em;
    border-radius: .3em;
    font-size: .8em;
    color: #FFFDD0;
}

#upload-link {
    background: #00b41e;
    padding: .4em;
    border-radius: .3em;
    font-size: .8em;
    color: #FFFDD0;
    margin-left: .6em;
}

.file-upload-btn {

    background: #00b41e;
    color: #FFF;
    font-size: 1.1em;
    width: 55px;
    height: 45px;
    text-align: center;
    border-radius: 5px;
    margin-top: 5%;
}

.uploadPostContainer {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    background: #161616;
    z-index: 2;

}

.upload-post-wrapper {
    margin-top: 18%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.upload-post-wrapper input[type="file"] {

    display: none;
}

.upload-files-div {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.upload-post-wrapper label {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: #00b41e;
    font-weight: bold;
    height: 45px;
    width: 40%;
    border-radius: 3px;
    padding: 5px;
}


.uploaded-file {
    width: 300px;
    height: 300px;
    margin-top: 2%;
}

.uploaded-file img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.uploaded-file video {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

#posts-loading {
    background-color: #00b41e;
    display: flex;
    align-items: center;
    justify-content: center; /* Center content horizontally */
    position: fixed; /* Use fixed instead of absolute to keep it centered */
    z-index: 2;
    left: 30%;
    bottom: 7%;
    height: 40px;
    width: 50%;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    animation: pulse 1s infinite; /* Add a pulse animation effect */
}

#posts-loading p {
    color: #FFFDD0;
    
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.loading-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #FFFDD0;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite; /* Add a spinning animation */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.close-post-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    background: #00b41e;
    border-radius: 50%;
    font-weight: bold;
    width: 40px;
    height: 40px;
    font-size: 1.5em;
    position: absolute;
    right: 1%;
    top: .5%;
}

.post-upload-caption {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.post-upload-caption .title {
    
    margin-top: 2%;
    margin-bottom: 2%;
    color: #FFF;
}

#title  {
    
    text-align: center;
    justify-content: center;
    
    height: auto;
    padding: .5em;
}

.upload-textarea {
    display: flex;
    width: 90%;
    justify-content: center;

}

.upload-textarea textarea {
    background: #FFFDD0;
    width: 90%;
    text-align: center;
    font-size: 1em;
    border-radius: 5px;
    outline-style: solid;
    outline-color: #00b41e;

}
.define-audience{

    display: flex;
    justify-content: space-between;
    height: 60px;
    align-items: center;
    
    
}
.audience-err{

    position: absolute;
    display: flex;
    align-items: center;
    top: 45%;
    left: 50%;
    height: 100px;
    transform: translate(-50%, -50%);
    background-color: #ed1c24;
    color: white;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    z-index: 9999;
}

.define-audience label{
    display: flex;
    height: 60px;
    width: 48%;
    
}

#title{

    margin-top: 2%;
    margin-bottom: 2%;
    color: #FFF;
}

/* Reset default padding and margin for elements */
 table, th, td {
    margin: 0;
    padding: 0;
  }
  

 
  
  /* Style for the admin dashboard container */
  .admin-dashboard {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 20px;
    color: whitesmoke;
    
  }

  #total-views{
    position: relative;
    width: 200%;
    left: -50%;
    margin-bottom: 15%;

  }
  
  /* Style for user views table */
  .user-views-table {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  /* Style for user activity table */
  .user-activity-table {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    margin-top: 20px;
  }
  
  /* Style for tables */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  /* Style for table headers */
  th {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    text-align: left;
  }
  
  /* Style for table data cells */
  td {
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  /* Alternate row background color */
  tr:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  /* Loading text style */
  .loading {
    text-align: center;
    padding: 20px;
    font-style: italic;
  }

 
 
  .meme-battles-container {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(255, 255, 255, 0.1);
    max-width: 600px;
    margin: 0 auto;
  }

  .meme-battles-container h1 {
    color: #FFFDD0;
    font-size: 24px;
    margin: 0;
    padding: 10px 0;
    line-height: 1.4;
    font-weight: bold;
  }

/* CSS for the link */
.gift-box {
    display: inline-block;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
    background: linear-gradient(to bottom, #00b41e, #008c16);
    color: #fff;
    transition: background 0.3s ease, color 0.3s ease;
  }
  
  .gift-box:hover {
    background: linear-gradient(to bottom, #008c16, #00b41e);
    color: #fff;
  }
  
  /* CSS for the icon and heading */
  .gift-box div {
    display: inline-block;
    background-color: #000; /* Background color for the icon container */
    padding: 10px;
    border-radius: 50%;
  }
  
  .gift-box h3 {
    margin: 0;
    padding-left: 10px;
    font-size: 18px;
    color: #333; /* Text color for the heading */
  }
  
.gift-box-container #table{

    color:#FFFDD0;
}

.update-airtime form{
    color: #DCDCDC;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
}

.airtime-fields{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100px;
    width: 100%;
    
}
.airtime-fields input[type="text"],#airtime-select{
height: 45px;
width: 80%;
border-radius: 5px;
font-size: 1em;
text-align: center;
}

.airtime-fields button{
    height: 45px;
    width: 80%;
    font-size: large;
    background: #00b41e;
    border-radius: 5px;
    }
    .airtime-fields label{
        padding: .5em;
        }



@media only screen and (min-width: 1024px) {

    /* desktop styles */

    .intro-meme img{

        width: 85%;
        height: 280px;
        object-fit: cover;
        border-radius: 8px;
    }



    body {
        width: 30%;
        margin: auto;
    }

    .main-navigation-menu {
        width: 50%;
        bottom: 0;
        left: 25%;
    }

    .commentsModal {
        width: 25%;
    }

    .comment-form {
        width: 16%;
        margin-left: 24%;
    }

    .sign-in-status .sign-in-page{
        margin-top: 20%;
        width: 40%;
    }

    .sign-in-status .sign-in-page button{
        
        width: 45%;
        margin: auto;
    }

    .search-result {
        width: 20%;
    }

    .header-details .search-input input[type='search'] {
        width: 30%;
       margin-left: 35%;
    }

    .uploadPostContainer {
        width: 30%;
       
        
    }


}

@media only screen and (min-device-width: 720px) and (max-device-width: 1023px) {

    /* tablet styles */
    body {
        width: 60%;
        margin: auto;
    }
}

.test-video {
    border: 10px solid silver;
    width: 95%;
    margin: auto;
    border-radius: 10px;
}

.test-video video {
    height: 70vh;
    width: 100%;
    object-fit: contain;

}

/* .profile{
    height: 8vh;
    width: 100%;
    border: 4px solid green;
}

.reactions{
    height: 8vh;
    width: 100%;
    border: 4px solid green;
} */


/*Policies*/

/* Container styles */
.policies {
    background-color: black; /* Assuming your background is already black */
    color: white; /* Text color */
    padding: 20px; /* Add padding for spacing */
    margin-bottom: 20%;
    
  }
  
  /* Heading styles */
  .policies h2 {
    color: #ff5722; /* Heading text color (orange) */
    margin-top: 20px; /* Add margin to separate headings */
  }
  
  /* List styles */
  .policies ul {
    list-style-type: disc; /* Use bullets for lists */
    margin-left: 20px; /* Add left margin for indentation */
  }
  
  /* List item styles */
 .policies li {
    margin-bottom: 10px; /* Add spacing between list items */
  }
  
  /* Strong (bold) text styles */
  .policies strong {
    font-weight: bold;
  }

  /*Error Modal*/

  /* Styling for the modal container */
/* Styling for the modal container */
.errorModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #4caf50;
    color: white;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    z-index: 1000; /* Ensure the modal appears above other elements */
    max-width: 400px; /* Adjust the maximum width as needed */
    border-radius: 5px;
  }
  
  /* Styling for the error message */
  .errorModal h3 {
   
    margin: 0;
    padding: 10px 0;
  }
  
  /* Close button (if needed) */
  .closeButton {
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;
    color: #333;
    font-size: 1.2em;
  }

  /* Add your CSS styles here or use a CSS-in-JS approach */
.download-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;
    z-index: 999;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  
  #download-btn {
    background-color: #c7c6b2;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  }

/** ADMIN**/

.users-total-number{

    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    font-size: 2em;
    height: 400px;
    width: 400px;
    border-radius: 50%;
    background: #009514;
}

.tv-btn{

    position: absolute;
    
    height: 40px;
    width: 40px;
    background: #009514;

}
  
  
  